import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import Header from "../components/Header"
import WaltzKore from "../components/WaltzKore"
import Footer from "../components/Footer"

class WaltzKoreZaTortuPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <meta charset="utf-8" />
          <title>WALTZ | Waltz Kore Za Tortu</title>
          <meta
            name="description"
            content="Izrada kolača nikada nije bila jednostavnija, jer sada imate
            koru za torte. Iznenadite svoje najmilije magijom koju
            napravite uz svoj Waltz proizvod. Uživajte!"
          />
          <meta
            name="image"
            content="https://waltz.rs/waltz_kore_za_tortu_seo.jpg"
          />
          <meta itemprop="name" content="WALTZ | Waltz Kore Za Tortu" />
          <meta
            itemprop="description"
            content="Izrada kolača nikada nije bila jednostavnija, jer sada imate
            koru za torte. Iznenadite svoje najmilije magijom koju
            napravite uz svoj Waltz proizvod. Uživajte!"
          />
          <meta
            itemprop="image"
            content="https://waltz.rs/waltz_kore_za_tortu_seo.jpg"
          />
          <meta name="og:title" content="WALTZ | Waltz Kore Za Tortu" />
          <meta
            name="og:description"
            content="Izrada kolača nikada nije bila jednostavnija, jer sada imate
            koru za torte. Iznenadite svoje najmilije magijom koju
            napravite uz svoj Waltz proizvod. Uživajte!"
          />
          <meta
            name="og:image"
            content="https://waltz.rs/waltz_kore_za_tortu_seo.jpg"
          />
          <meta name="og:url" content="https://waltz.rs/waltz-roll-mini" />
          <meta name="og:site_name" content="WALTZ | Waltz Kore Za Tortu" />
          <meta name="og:locale" content="sr" />
          <meta name="og:type" content="website" />
        </Helmet>
        <Header />
        <WaltzKore />
        <Footer />
      </Layout>
    )
  }
}
export default WaltzKoreZaTortuPage
