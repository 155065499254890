import React from "react"

import waltz_kore_1 from "../images/products/waltz_kore_za_tortu_1.jpg"
import waltz_kore_2 from "../images/products/waltz_kore_za_tortu_2.jpg"
import waltz_kore_3 from "../images/products/waltz_kore_za_tortu_3.jpg"

class WaltzCream extends React.Component {
  render() {
    return (
      <section id="proizvodi" className="hero products-page default-page">
        <div className="container padding-0 margin-0">
          <div className="columns margin-0 main">
            <div className="column is-4 static-part is-left padding-0">
              <div className="content">
                <div className="title">WALTZ KORE ZA TORTU</div>
                <span className="zigzag"></span>
                <p className="text">
                  Izrada kolača nikada nije bila jednostavnija, jer sada imate
                  koru za torte. Iznenadite svoje najmilije magijom koju
                  napravite uz svoj Waltz proizvod. Uživajte!
                </p>
              </div>
            </div>
            {/*STATIC PART END */}
            <div className="column is-8 content-part is-right padding-0">
              <div className="columns is-multiline inner margin-0">
                <div className="column is-6 single-product padding-0">
                  <img src={waltz_kore_1} alt="Waltz kore za tortu" />
                </div>
                <div className="column is-6 single-product padding-0">
                  <img src={waltz_kore_2} alt="Waltz kore za tortu" />
                </div>
                <div className="column is-6 single-product padding-0">
                  <img src={waltz_kore_3} alt="Waltz kore za tortu" />
                </div>
              </div>
            </div>
            {/* PRODUCTS PART END */}
          </div>
          {/* COLUMNS END */}
        </div>
        {/* CONTAINER END */}
      </section> /* PRODUCTS PAGE SECTION END */
    )
  }
}

export default WaltzCream
